import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/images/hero-mevaho-mobile.png'
import _imports_1 from '@/images/hero-gambusaki.png'
import _imports_2 from '@/images/hero-khoja.webp'
import _imports_3 from '@/images/hero-kissaho-mobile.png'
import _imports_4 from '@/images/hero-pearl-mobile.png'
import _imports_5 from '@/images/hero-silk-way.webp'
import _imports_6 from '@/images/hero-misr-mobile.png'
import _imports_7 from '@/images/hero-pamir.png'
import _imports_8 from '@/images/hero-astro-logo-mobile.png'
import _imports_9 from '@/images/hero-mevaho-gold.webp'
import _imports_10 from '@/images/hero-gambusaki-gold-mobile.webp'


const _withScopeId = n => (_pushScopeId("data-v-1006796f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mobile-header" }
const _hoisted_2 = {
  key: 0,
  class: "mobile-header__hero",
  src: _imports_0,
  alt: "mevaho icon"
}
const _hoisted_3 = {
  key: 1,
  class: "mobile-header__hero",
  src: _imports_1,
  alt: "gambusaki icon"
}
const _hoisted_4 = {
  key: 2,
  class: "mobile-header__hero",
  src: _imports_2,
  alt: "khoja icon"
}
const _hoisted_5 = {
  key: 3,
  class: "mobile-header__hero",
  src: _imports_3,
  alt: "kissaho icon"
}
const _hoisted_6 = {
  key: 4,
  class: "mobile-header__hero",
  src: _imports_4,
  alt: "pearl icon"
}
const _hoisted_7 = {
  key: 5,
  class: "mobile-header__hero",
  src: _imports_5,
  alt: "silk-way icon"
}
const _hoisted_8 = {
  key: 6,
  class: "mobile-header__hero",
  src: _imports_6,
  alt: "misr icon"
}
const _hoisted_9 = {
  key: 7,
  class: "mobile-header__hero",
  src: _imports_7,
  alt: "pamir icon"
}
const _hoisted_10 = {
  key: 8,
  class: "mobile-header__hero",
  src: _imports_8,
  alt: "pamir icon"
}
const _hoisted_11 = {
  key: 9,
  class: "mobile-header__hero",
  src: _imports_9,
  alt: "mg icon"
}
const _hoisted_12 = {
  key: 10,
  class: "mobile-header__heroGold",
  src: _imports_10,
  alt: "gambusaki icon"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MenuTop = _resolveComponent("MenuTop")!
  const _component_MenuWrapper = _resolveComponent("MenuWrapper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_MenuTop),
    _createVNode(_component_MenuWrapper),
    (_ctx.gameId === 'loto-mevaho-5')
      ? (_openBlock(), _createElementBlock("img", _hoisted_2))
      : (_ctx.gameId === 'loto-gambusaki-9')
        ? (_openBlock(), _createElementBlock("img", _hoisted_3))
        : (_ctx.gameId === 'loto-khoja')
          ? (_openBlock(), _createElementBlock("img", _hoisted_4))
          : (_ctx.gameId === 'loto-kissaho')
            ? (_openBlock(), _createElementBlock("img", _hoisted_5))
            : (_ctx.gameId === 'loto-pearl')
              ? (_openBlock(), _createElementBlock("img", _hoisted_6))
              : (_ctx.gameId === 'loto-silk-way')
                ? (_openBlock(), _createElementBlock("img", _hoisted_7))
                : (_ctx.gameId === 'loto-misr')
                  ? (_openBlock(), _createElementBlock("img", _hoisted_8))
                  : (_ctx.gameId === 'loto-pamir-legends')
                    ? (_openBlock(), _createElementBlock("img", _hoisted_9))
                    : (_ctx.gameId === 'loto-astrologer')
                      ? (_openBlock(), _createElementBlock("img", _hoisted_10))
                      : (_ctx.gameId === 'loto-funny-fruits')
                        ? (_openBlock(), _createElementBlock("img", _hoisted_11))
                        : (_openBlock(), _createElementBlock("img", _hoisted_12))
  ]))
}