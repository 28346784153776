import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/images/hero-mevaho.webp'
import _imports_1 from '@/images/hero-gambusaki.png'
import _imports_2 from '@/images/hero-gambusaki-gold.webp'
import _imports_3 from '@/images/hero-khoja.webp'
import _imports_4 from '@/images/hero-kissaho.webp'
import _imports_5 from '@/images/hero-pearl.webp'
import _imports_6 from '@/images/hero-astrologer.webp'
import _imports_7 from '@/images/hero-silk-way.webp'
import _imports_8 from '@/images/hero-misr.webp'
import _imports_9 from '@/images/hero-pamir-legends.webp'
import _imports_10 from '@/images/hero-mevaho-gold.webp'


const _withScopeId = n => (_pushScopeId("data-v-3258165b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main-header" }
const _hoisted_2 = {
  key: 1,
  class: "main-header__text"
}
const _hoisted_3 = {
  key: 2,
  class: "main-header__imageMevaho",
  src: _imports_0,
  alt: "mevaho image"
}
const _hoisted_4 = {
  key: 3,
  class: "main-header__imageGambusaki",
  src: _imports_1,
  alt: "gambusaki image"
}
const _hoisted_5 = {
  key: 4,
  class: "main-header__imageGambusakiGold",
  src: _imports_2,
  alt: "gambusaki image"
}
const _hoisted_6 = {
  key: 5,
  class: "main-header__imageKhoja",
  src: _imports_3,
  alt: "man image"
}
const _hoisted_7 = {
  key: 6,
  class: "main-header__imageKissaho",
  src: _imports_4,
  alt: "kissaho image"
}
const _hoisted_8 = {
  key: 7,
  class: "main-header__imagePearl",
  src: _imports_5,
  alt: "pearl image"
}
const _hoisted_9 = {
  key: 8,
  class: "main-header__imageAstro",
  src: _imports_6,
  alt: "astro image"
}
const _hoisted_10 = {
  key: 9,
  class: "main-header__imageSilkWay",
  src: _imports_7,
  alt: "silk-way image"
}
const _hoisted_11 = {
  key: 10,
  class: "main-header__imageMisr",
  src: _imports_8,
  alt: "misr image"
}
const _hoisted_12 = {
  key: 11,
  class: "main-header__imagePamir",
  src: _imports_9,
  alt: "pamir image"
}
const _hoisted_13 = {
  key: 12,
  class: "main-header__imageMevahoGold",
  src: _imports_10,
  alt: "mg image"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SimpleInfo = _resolveComponent("SimpleInfo")!
  const _component_MenuTop = _resolveComponent("MenuTop")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isWidescreen)
      ? (_openBlock(), _createBlock(_component_SimpleInfo, { key: 0 }))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.$t("combinationChoose")), 1)),
    (_ctx.gameId === 'loto-mevaho-5')
      ? (_openBlock(), _createElementBlock("img", _hoisted_3))
      : (_ctx.gameId === 'loto-gambusaki-9')
        ? (_openBlock(), _createElementBlock("img", _hoisted_4))
        : (_ctx.gameId === 'loto-gambusaki-gold-9')
          ? (_openBlock(), _createElementBlock("img", _hoisted_5))
          : (_ctx.gameId === 'loto-khoja')
            ? (_openBlock(), _createElementBlock("img", _hoisted_6))
            : (_ctx.gameId === 'loto-kissaho')
              ? (_openBlock(), _createElementBlock("img", _hoisted_7))
              : (_ctx.gameId === 'loto-pearl')
                ? (_openBlock(), _createElementBlock("img", _hoisted_8))
                : (_ctx.gameId === 'loto-astrologer')
                  ? (_openBlock(), _createElementBlock("img", _hoisted_9))
                  : (_ctx.gameId === 'loto-silk-way')
                    ? (_openBlock(), _createElementBlock("img", _hoisted_10))
                    : (_ctx.gameId === 'loto-misr')
                      ? (_openBlock(), _createElementBlock("img", _hoisted_11))
                      : (_ctx.gameId === 'loto-pamir-legends')
                        ? (_openBlock(), _createElementBlock("img", _hoisted_12))
                        : (_ctx.gameId === 'loto-funny-fruits')
                          ? (_openBlock(), _createElementBlock("img", _hoisted_13))
                          : _createCommentVNode("", true),
    _createVNode(_component_MenuTop)
  ]))
}