
import { defineComponent } from "vue";
import MainContainer from "@/components/MainContainer.vue";
import MenuWrapper from "@/components/MenuWrapper.vue";
import DoublingHeader from "@/components/DoublingHeader.vue";
import PurchasePanel from "@/components/PurchasePanel.vue";
import InputNice from "@/components/UI/InputNice.vue";
import store from "@/store";
import DoublingDesktop from "@/components/DoublingDesktop.vue";
import DoublingMobile from "@/components/Mobile/DoublingMobile.vue";
import router from "@/router";
import DoublingDesktopKhoja from "@/components/Khoja/DoublingDesktopKhoja.vue";
import DoublingMobileKhoja from "@/components/Khoja/DoublingMobileKhoja.vue";
import DoublingDesktopMevahoGold from "@/components/DoublingDesktopMevahoGold.vue";

export default defineComponent({
  name: "doubling",
  components: {
    DoublingMobile,
    DoublingDesktop,
    InputNice,
    PurchasePanel,
    DoublingHeader,
    MenuWrapper,
    MainContainer,
    DoublingDesktopKhoja,
    DoublingMobileKhoja,
    DoublingDesktopMevahoGold,
  },
  computed: {
    lastPayout(): number {
      return store.state.ui.lastPayout;
    },
    isDesktop(): boolean {
      return store.state.uiVersion === "desktop";
    },
    isKhoja(): boolean {
      return store.state.currentGameId === "loto-khoja";
    },
    isMevahoGold(): boolean {
      return store.state.currentGameId === "loto-funny-fruits";
    },
  },
  // beforeCreate() {
  //   if (!store.state.doubling.isOn) {
  //     router.push("/");
  //   }
  // },
});
