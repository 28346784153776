import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-16d98afa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DoublingDesktopKhoja = _resolveComponent("DoublingDesktopKhoja")!
  const _component_DoublingDesktop = _resolveComponent("DoublingDesktop")!
  const _component_DoublingMobileKhoja = _resolveComponent("DoublingMobileKhoja")!
  const _component_DoublingMobile = _resolveComponent("DoublingMobile")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isDesktop)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.isKhoja)
            ? (_openBlock(), _createBlock(_component_DoublingDesktopKhoja, { key: 0 }))
            : (_openBlock(), _createBlock(_component_DoublingDesktop, { key: 1 }))
        ], 64))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (_ctx.isKhoja)
            ? (_openBlock(), _createBlock(_component_DoublingMobileKhoja, { key: 0 }))
            : (_openBlock(), _createBlock(_component_DoublingMobile, { key: 1 }))
        ], 64))
  ]))
}