import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-427b42c3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main-screen__messageContainer" }
const _hoisted_2 = {
  key: 0,
  class: "main-screen__messagePromo"
}
const _hoisted_3 = { class: "main-screen__message" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { class: "main-screen__info" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MobileHeader = _resolveComponent("MobileHeader")!
  const _component_ScoreboardWrapper = _resolveComponent("ScoreboardWrapper")!
  const _component_SimpleInfo = _resolveComponent("SimpleInfo")!
  const _component_PurchasePanel = _resolveComponent("PurchasePanel")!
  const _component_ModalBonus = _resolveComponent("ModalBonus")!
  const _component_ModalPromo = _resolveComponent("ModalPromo")!
  const _component_ModalBuyin = _resolveComponent("ModalBuyin")!
  const _component_ControlPanel = _resolveComponent("ControlPanel")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["main-screen", { '--isBonus': _ctx.isBonusGamePamirLegends }]),
    style: _normalizeStyle(_ctx.isPamir && _ctx.pamirBackgroundsStyle)
  }, [
    _createVNode(_component_MobileHeader, { class: "main-screen__header" }),
    _createVNode(_component_ScoreboardWrapper),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.promoIsOn)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("span", null, _toDisplayString(_ctx.$t("promoMode")) + _toDisplayString(" ") + _toDisplayString(_ctx.promoTicketsLeft) + " " + _toDisplayString(" ") + _toDisplayString(_ctx.$t("promoFrom")) + _toDisplayString(" ") + " " + _toDisplayString(_ctx.promoCount) + _toDisplayString(" ") + " " + _toDisplayString(_ctx.$t("promoCount")) + _toDisplayString(" "), 1)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, [
        (_ctx.messageStatus && !(_ctx.messageStatus === 'won' && _ctx.lastPayout === '0.00'))
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              (_ctx.scoreboardMode === 'digit')
                ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.$t(`information.digit.${_ctx.messageStatus}`, {
                n: _ctx.stepPayout,
                c: _ctx.currencyIcon,
                a: _ctx.currentBonusGameCount,
                b: _ctx.numBonusGames,
                y: _ctx.isSamePayout ? _ctx.totalPayoutText : "",
              })), 1))
                : (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.$t(`information.fruit.${_ctx.messageStatus}`, {
                n: _ctx.stepPayout,
                c: _ctx.currencyIcon,
                a: _ctx.currentBonusGameCount,
                b: _ctx.numBonusGames,
                y: _ctx.isSamePayout ? _ctx.totalPayoutText : "",
              })), 1))
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(_component_SimpleInfo)
    ]),
    _createVNode(_component_PurchasePanel),
    _createVNode(_component_ModalBonus),
    _createVNode(_component_ModalPromo),
    _createVNode(_component_ModalBuyin),
    (!_ctx.isBetstop)
      ? (_openBlock(), _createBlock(_component_ControlPanel, { key: 0 }))
      : _createCommentVNode("", true)
  ], 6))
}