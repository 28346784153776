
import { defineComponent } from "vue";
import store from "@/store";
import { GetLastDoublingGameResponse } from "@/types/doubling";

export default defineComponent({
  name: "last-boxes-row",

  data() {
    return {
      boxRightOpen: require("@/images/boxes/boxes-right-open-mobile.png"),
      boxLeftOpen: require("@/images/boxes/boxes-left-open-mobile.png"),
      boxRightOpenActive: require("@/images/boxes/right-box-open-active-mobile.png"),
      boxLeftOpenActive: require("@/images/boxes/left-box-open-active-mobile.png"),
      boxRightOpenActiveKissaho: require("@/images/boxes/kissaho-box-right-open-active-mobile.png"),
      boxLeftOpenActiveKissaho: require("@/images/boxes/kissaho-box-left-open-active-mobile.png"),
      isVisibleLeftArrow: false,
      isVisibleRightArrow: true,
    };
  },

  computed: {
    updateLastDoublingGames(): GetLastDoublingGameResponse {
      return this.isMevahoGold
        ? store.state.doubling.lastDoublingGames.slice(0, 4)
        : store.state.doubling.lastDoublingGames;
    },

    isKhoja(): boolean {
      return store.state.currentGameId === "loto-khoja";
    },
    isKissaho(): boolean {
      return store.state.currentGameId === "loto-kissaho";
    },
    isSilkWay(): boolean {
      return store.state.currentGameId === "loto-silk-way";
    },
    isPamirLegends(): boolean {
      return store.state.currentGameId === "loto-pamir-legends";
    },
    isPearl(): boolean {
      return store.state.currentGameId === "loto-pearl";
    },

    isAstro(): boolean {
      return store.state.currentGameId === "loto-astrologer";
    },

    isMisr(): boolean {
      return store.state.currentGameId === "loto-misr";
    },
    isMevahoGold(): boolean {
      return store.state.currentGameId === "loto-funny-fruits";
    },
    imgLeftOpenBox(): string {
      return this.isKhoja
        ? require("@/images/boxes/box-khoja-left-open-mobile.png")
        : this.isKissaho
        ? require("@/images/boxes/kissaho-box-left-open-mobile.png")
        : this.isSilkWay
        ? require("@/images/boxes/silk-way-bag-left-open-mobile.png")
        : this.isPamirLegends
        ? require("@/images/boxes/pamir-rock-left-open-mobile.png")
        : this.isPearl
        ? require("@/images/boxes/pearl-box-left-open-mobile.png")
        : this.isAstro
        ? require("@/images/boxes/box-left-open-astro.png")
        : this.isMisr
        ? require("@/images/boxes/misr-box-left-open-mobile.png")
        : this.isMevahoGold
        ? require("@/images/boxes/mevaho-gold-box-left-open.png")
        : require("@/images/boxes/boxes-left-open-mobile.png");
    },
    imgRightOpenBox(): string {
      return this.isKhoja
        ? require("@/images/boxes/box-khoja-right-open-mobile.png")
        : this.isKissaho
        ? require("@/images/boxes/kissaho-box-right-open-mobile.png")
        : this.isSilkWay
        ? require("@/images/boxes/silk-way-bag-right-open-mobile.png")
        : this.isPamirLegends
        ? require("@/images/boxes/pamir-rock-right-open-mobile.png")
        : this.isPearl
        ? require("@/images/boxes/pearl-box-right-open-mobile.png")
        : this.isAstro
        ? require("@/images/boxes/box-right-open-astro.png")
        : this.isMisr
        ? require("@/images/boxes/misr-box-right-open-mobile.png")
        : this.isMevahoGold
        ? require("@/images/boxes/mevaho-gold-box-right-open.png")
        : require("@/images/boxes/boxes-right-open-mobile.png");
    },
    imgLeftActiveBox(): string {
      return this.isKhoja
        ? require("@/images/boxes/box-khoja-left-open-mobile.png")
        : this.isKissaho
        ? require("@/images/boxes/kissaho-box-left-open-active-mobile.png")
        : this.isSilkWay
        ? require("@/images/boxes/silk-way-bag-left-open-active-mobile.png")
        : this.isPamirLegends
        ? require("@/images/boxes/pamir-rock-left-open-active-mobile.png")
        : this.isPearl
        ? require("@/images/boxes/pearl-box-left-open-active-mobile.png")
        : this.isAstro
        ? require("@/images/boxes/box-left-open-astro-active.png")
        : this.isMisr
        ? require("@/images/boxes/misr-box-left-open-active-mobile.png")
        : this.isMevahoGold
        ? require("@/images/boxes/mevaho-gold-box-left-open-active.png")
        : require("@/images/boxes/left-box-open-active-mobile.png");
    },
    imgRightActiveBox(): string {
      return this.isKhoja
        ? require("@/images/boxes/box-khoja-right-open-mobile.png")
        : this.isKissaho
        ? require("@/images/boxes/kissaho-box-right-open-active-mobile.png")
        : this.isSilkWay
        ? require("@/images/boxes/silk-way-bag-right-open-active-mobile.png")
        : this.isPamirLegends
        ? require("@/images/boxes/pamir-rock-right-open-active-mobile.png")
        : this.isPearl
        ? require("@/images/boxes/pearl-box-right-open-active-mobile.png")
        : this.isAstro
        ? require("@/images/boxes/box-right-open-astro-active.png")
        : this.isMisr
        ? require("@/images/boxes/misr-box-right-open-active-mobile.png")
        : this.isMevahoGold
        ? require("@/images/boxes/mevaho-gold-box-right-open-active.png")
        : require("@/images/boxes/right-box-open-active-mobile.png");
    },
  },

  methods: {
    scrollToEnd() {
      (this.$refs.scroll as HTMLInputElement).scrollLeft -= 80;
    },
    scrollToStart() {
      (this.$refs.scroll as HTMLInputElement).scrollLeft += 80;
    },
    scrollHandler(e: any) {
      this.isVisibleLeftArrow = e.target.scrollLeft > 20 ? true : false;
      this.isVisibleRightArrow = e.target.scrollLeft > 80 ? false : true;
    },
  },
});
