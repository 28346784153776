import State from "./state";
import router from "@/router";
import store, { ACtx } from "@/store";
import {
  DoublingGame,
  GetLastDoublingGameRequest,
  GetLastDoublingGameResponse,
  PlayDoublingGameRequest,
  PlayDoublingGameResponse,
} from "@/types/doubling";
import { api } from "@/api";
import storeEvents from "@/store-events";
import { AudioUtils, truncTwo } from "@/utils";
import {
  CLOSE_DOUBLING_AFTER_LOST_TIMEOUT,
  OPEN_BOX_TIMEOUT,
  OPEN_BOX_TIMEOUT_SILK_WAY,
} from "@/constants";
import {
  calculateGambusakiTicketGlow,
  calculateKhojaTicketGlow,
  calculateMevahoTicketGlow,
} from "@/utils/ticket-calculator";

export type DoublingState = {
  isOn: boolean;
  expectedWinnings: number[];
  expectedWinningsTemp: number[];
  countGame: number;
  lastGameInfo: PlayDoublingGameResponse;
  confirmTimeout: number;
  autoConfirmTimeout: number;
  digitWinnings: string[];
  isDigitDoublingAnim: boolean;
  isTakePayout: boolean;
  lastDoublingGames: GetLastDoublingGameResponse;
  split: number;
  currentAmount: number;
  deferredBalance: number;
};

export const state: DoublingState = {
  isOn: false,
  expectedWinnings: [100, 200, 400, 800, 1600, 3200],
  expectedWinningsTemp: [100, 200, 400, 800, 1600, 3200],
  digitWinnings: new Array(5),
  countGame: 0,
  lastGameInfo: {
    doubling_game_id: undefined,
    payout: 0,
    amount: 0,
    status: "",
  },
  confirmTimeout: 0,
  autoConfirmTimeout: 0,
  isDigitDoublingAnim: false,
  isTakePayout: true,
  lastDoublingGames: [],
  split: 0,
  currentAmount: 0,
  deferredBalance: 0,
};

let doublingTimer: undefined | number = undefined;
let autoDoublingTimer: undefined | number = undefined;
let mevahoGoldTimer: any = null;

export const mutations = {
  setIsDoublingOn(state: State, value: boolean) {
    state.doubling.isOn = value;
  },
  updateExpectedWinnings(state: State, payout: number) {
    state.doubling.expectedWinnings = [
      payout * 1,
      payout * 2,
      payout * 4,
      payout * 8,
      payout * 16,
      payout * 32,
    ];
    state.doubling.expectedWinningsTemp = [
      payout * 1,
      payout * 2,
      payout * 4,
      payout * 8,
      payout * 16,
      payout * 32,
    ];
    state.doubling.currentAmount = payout;
  },
  updateCurrentAmount(state: State, value: number) {
    state.doubling.currentAmount = value;
  },
  updateDeferredBalance(state: State, value: number) {
    state.doubling.deferredBalance = value;
  },
  updateExpectedWinningsTemp(state: State) {
    state.doubling.expectedWinningsTemp = [...state.doubling.expectedWinnings];
  },
  updateOnlyExpectedWinnings(state: State, value: number[]) {
    state.doubling.expectedWinnings = value;
  },
  updateDigitWinnings(state: State, won: boolean) {
    const currentCountGame = state.doubling.countGame;
    const tempArrayWinnings = state.doubling.digitWinnings;
    tempArrayWinnings.splice(currentCountGame, 1, won ? "x2" : "x0");
    state.doubling.digitWinnings = tempArrayWinnings;
  },
  setDigitWinnings(state: State, games: PlayDoublingGameResponse[]) {
    games.forEach((game, index) => {
      const tempArrayWinnings = state.doubling.digitWinnings;
      tempArrayWinnings.splice(index, 1, game.status === "won" ? "x2" : "x0");
      state.doubling.digitWinnings = tempArrayWinnings;
    });
  },
  resetDigitWinnings(state: State) {
    state.doubling.digitWinnings = new Array(5);
  },
  setIsDigitDoublingAnim(state: State, value: boolean) {
    state.doubling.isDigitDoublingAnim = value;
  },
  updateLastGameInfo(state: State, data: PlayDoublingGameResponse) {
    state.doubling.lastGameInfo = data;
  },
  resetGameInfo(state: State) {
    state.doubling.lastGameInfo = {
      doubling_game_id: undefined,
      payout: 0,
      amount: 0,
      status: "",
    };
  },
  setConfirmTimeout(state: State, value: number) {
    state.doubling.confirmTimeout = value;
  },
  setAutoConfirmTimeout(state: State, value: number) {
    state.doubling.autoConfirmTimeout = value;
  },
  addStepToCount(state: State) {
    state.doubling.countGame += 1;
  },
  setCountGame(state: State, value: number) {
    state.doubling.countGame = value;
  },
  clearCount(state: State) {
    state.doubling.countGame = 0;
  },
  addStepSplit(state: State) {
    state.doubling.split += 1;
  },
  clearSplit(state: State) {
    state.doubling.split = 0;
  },
  setIsTakePayout(state: State, value: boolean) {
    state.doubling.isTakePayout = value;
  },

  updateLastDoublingGames(state: State, data: GetLastDoublingGameResponse) {
    state.doubling.lastDoublingGames = data;
  },
};

export const actions = {
  startDoublingTimer({ state, commit, dispatch }: ACtx) {
    // const countDown = Date.now() + state.settings.doubling_game.confirm_delay + 10;
    // const now = Date.now();
    // const distance = countDown - now;
    const initTimeout = state.settings.doubling_game.confirm_delay / 1000;
    commit("setConfirmTimeout", initTimeout);
    dispatch("stopDoublingTimer");
    doublingTimer = window.setInterval(() => {
      if (state.doubling.confirmTimeout === 0) {
        commit("setConfirmTimeout", 0);
        clearInterval(doublingTimer);
        return;
      }
      const newValue = state.doubling.confirmTimeout - 1;
      commit("setConfirmTimeout", newValue);
    }, 1000);
  },

  startAutoDoublingTimer({ state, commit, dispatch }: ACtx, customTimer: boolean = false) {
    const numbers = state.currentTicket ? state.currentTicket.draw.numbers : [];
    const currentCombination = state.currentTicket && state.currentTicket.combination;
    const wonLength = () => {
      switch (state.currentGameId) {
        case "loto-gambusaki-gold-9":
        case "loto-gambusaki-9":
          return calculateGambusakiTicketGlow(numbers, currentCombination!, state.currentGameId)
            .length;
        case "loto-mevaho-5":
        case "loto-funny-fruits":
          return calculateMevahoTicketGlow(numbers, currentCombination!, state.currentGameId)
            .length;
        case "loto-khoja":
        case "loto-kissaho":
        case "loto-pearl":
        case "loto-silk-way":
        case "loto-pamir-legends":
        case "loto-astrologer":
          return calculateKhojaTicketGlow(numbers, currentCombination!, state.currentGameId).length;
        case "loto-misr":
          return calculateKhojaTicketGlow(numbers, currentCombination!, state.currentGameId).length;
        default:
          return 0;
      }
    };
    const autoTimer = wonLength() ? wonLength() * 2 + 1 : 3;
    //console.log("timer: ", autoTimer);

    const initTimeout = customTimer ? 10 : autoTimer;

    commit("setAutoConfirmTimeout", initTimeout);
    dispatch("stopAutoDoublingTimer");
    autoDoublingTimer = window.setInterval(() => {
      if (state.doubling.autoConfirmTimeout === 0) {
        commit("setAutoConfirmTimeout", 0);
        clearInterval(autoDoublingTimer);
        return;
      }
      const newValue = state.doubling.autoConfirmTimeout - 1;
      commit("setAutoConfirmTimeout", newValue);
    }, 1000);
  },

  mevahoGoldAutoDoublingTimer({ state, commit, dispatch }: ACtx) {
    const numbers = state.currentTicket ? state.currentTicket.draw.numbers : [];
    const currentCombination = state.currentTicket && state.currentTicket.combination;
    const wonLength = () => {
      switch (state.currentGameId) {
        case "loto-gambusaki-gold-9":
        case "loto-gambusaki-9":
          return calculateGambusakiTicketGlow(numbers, currentCombination!, state.currentGameId)
            .length;
        case "loto-mevaho-5":
        case "loto-funny-fruits":
          return calculateMevahoTicketGlow(numbers, currentCombination!, state.currentGameId)
            .length;
        case "loto-khoja":
        case "loto-kissaho":
        case "loto-pearl":
        case "loto-silk-way":
        case "loto-pamir-legends":
        case "loto-astrologer":
          return calculateKhojaTicketGlow(numbers, currentCombination!, state.currentGameId).length;
        case "loto-misr":
          return calculateKhojaTicketGlow(numbers, currentCombination!, state.currentGameId).length;
        default:
          return 0;
      }
    };
    const autoTimer = wonLength() ? wonLength() * 2 + 1 : 3;
    //console.log("timer: ", autoTimer);
    const toDoublingTimer = 700 + autoTimer * 1000;
    if (
      store.state.currentGameId === "loto-funny-fruits" &&
      !store.state.ui.autoGame &&
      store.state.uiVersion === "mobile" &&
      store.state.userData.settings.mevaho_mode === "fruit"
    ) {
      mevahoGoldTimer = setTimeout(() => {
        router.push("/doubling");
        store.commit("setIsDoublingOn", true);
      }, toDoublingTimer);
    } else if (
      store.state.currentGameId === "loto-funny-fruits" &&
      !store.state.ui.autoGame &&
      store.state.uiVersion === "desktop" &&
      store.state.userData.settings.mevaho_mode === "fruit"
    ) {
      mevahoGoldTimer = setTimeout(() => {
        store.commit("setIsDoublingOn", true);
      }, toDoublingTimer);
    }
  },

  resetMevahoGoldAutoDoublingTimer() {
    if (mevahoGoldTimer) {
      clearTimeout(mevahoGoldTimer);
      mevahoGoldTimer = null;
      console.log("таймер отменен");
    }
  },

  stopDoublingTimer() {
    clearInterval(doublingTimer);
  },

  stopAutoDoublingTimer() {
    clearInterval(autoDoublingTimer);
  },

  async stopDoublingGame({ state, commit, dispatch }: ACtx) {
    commit("setIsDoublingOn", false);
    commit("setIsDigitDoublingAnim", false);
    commit("setIsTakePayout", true);
    commit("setMultiButtonMode", "autoGame");
    if (!store.state.ui.autoGame) {
      store.commit("setIsBuyinMode", false);
    }
    await router.push("/");
    storeEvents.emit("stopAnimation");
  },

  async applyDoublingGame({ state, commit, dispatch }: ACtx, value: string | undefined) {
    const playoutDelay = state.settings.doubling_game.playout_delay;
    dispatch("stopDoublingTimer");
    dispatch("stopAutoDoublingTimer");
    commit("setIsTakePayout", false);
    // storeEvents.emit("nextPlayGame");
    const payload: DoublingGame = {
      ticket_id:
        state.currentTicket && state.currentTicket.ticket_id ? state.currentTicket.ticket_id : 0,
      participate: true,
      last_doubling_game_id: state.doubling.lastGameInfo.doubling_game_id,
    };

    try {
      const result = await api.doublingGame(payload);
      commit("setIsDoublingOn", true);
      dispatch("startPlayDoubling", value);
    } catch (e: any) {
      console.error("error: ", e);
      if (e.code === 13) {
        await dispatch("stopDoublingGame");
        await dispatch("toggleModal", "expired");
      }
    }
  },

  async closeDoublingGame({ state, commit, dispatch }: ACtx) {
    commit("setAutoMakeTicketBlocked", false);
    dispatch("stopDoublingTimer");
    dispatch("stopAutoDoublingTimer");
    if (state.ui.autoGame) commit("setAutoMakeTicketBlocked", true);
    const payload: DoublingGame = {
      ticket_id:
        state.currentTicket && state.currentTicket.ticket_id ? state.currentTicket.ticket_id : 0,
      participate: false,
      last_doubling_game_id: state.doubling.lastGameInfo.doubling_game_id,
    };

    try {
      const result = await api.doublingGame(payload);
    } catch (e: any) {
      console.error("error: ", e);
      if (e.code === 13) {
        commit("setAutoMakeTicketBlocked", false);
        dispatch("autoMakeTicket", { delay: false, force: false });
      }
    } finally {
      await dispatch("stopDoublingGame");
      if (!state.ui.autoGame) await dispatch("autoMakeTicket", { delay: false, force: false });
    }
    storeEvents.emit("stopAnimation");
  },

  async getLastDoublingGames({ state, commit, dispatch }: ACtx) {
    const payload: GetLastDoublingGameRequest = {
      limit: 6,
    };
    try {
      const data = await api.getLastDoublingGames(payload);
      if (state.currentGameId === "loto-silk-way") {
        setTimeout(() => {
          commit("updateLastDoublingGames", data);
        }, 1000);
      } else {
        commit("updateLastDoublingGames", data);
      }
    } catch (e: any) {
      console.error("error: ", e);
    }
  },

  async startPlayDoubling({ state, commit, dispatch }: ACtx, value: string | undefined) {
    commit("setButtonDoublingDisabled", true);
    const confirmDelay = state.settings.doubling_game.confirm_delay;
    const split = state.doubling.split;
    const payload: PlayDoublingGameRequest = {
      ticket_id:
        state.currentTicket && state.currentTicket.ticket_id ? state.currentTicket.ticket_id : 0,
      last_doubling_game_id: state.doubling.lastGameInfo.doubling_game_id,
      sign: value,
      split: split > 0 ? split : undefined,
    };

    try {
      const result = await api.playDoublingGame(payload);
      const isDoublingWon = result.status === "won";
      commit("updateLastGameInfo", result);
      commit("updateDigitWinnings", isDoublingWon);
      storeEvents.emit("isPlayDoublingWon", isDoublingWon);
      dispatch("getLastDoublingGames");
      commit("clearSplit");
      if (state.currentGameId === "loto-silk-way") {
        setTimeout(() => {
          commit("updateExpectedWinningsTemp");
        }, 800);
      } else {
        commit("updateExpectedWinningsTemp");
      }

      if (isDoublingWon) {
        dispatch("startDoublingTimer");
      }

      if (
        state.userData.settings.mevaho_mode === "fruit" &&
        state.currentGameId === "loto-mevaho-5"
      ) {
        if (isDoublingWon) {
          AudioUtils.playSound("breakdown-chests-win", 0, 1);
        } else {
          AudioUtils.playSound("breakdown-chests-loss", 0, 1);
        }
      } else if (
        state.userData.settings.mevaho_mode === "fruit" &&
        state.currentGameId === "loto-funny-fruits"
      ) {
        if (isDoublingWon) {
          AudioUtils.playSound("breakdown-chests-win-mevaho-gold", 0, 3);
        } else {
          AudioUtils.playSound("breakdown-chests-loss-mevaho-gold", 0, 3);
        }
      } else if (
        state.userData.settings.mevaho_mode === "fruit" &&
        (state.currentGameId === "loto-gambusaki-9" ||
          state.currentGameId === "loto-gambusaki-gold-9")
      ) {
        if (isDoublingWon) {
          AudioUtils.playSound("breakdown-chests-win-gambusaki", 0, 1);
        } else {
          AudioUtils.playSound("breakdown-chests-loss-gambusaki", 0, 1);
        }
      } else if (
        state.userData.settings.mevaho_mode === "digit" &&
        state.currentGameId === "loto-mevaho-5"
      ) {
        if (isDoublingWon) {
          AudioUtils.playSound("receipt-win-drop-sound", 0, 3);
        }
      } else if (
        state.userData.settings.mevaho_mode === "digit" &&
        state.currentGameId === "loto-funny-fruits"
      ) {
        if (isDoublingWon) {
          AudioUtils.playSound("receipt-win-drop-sound", 0, 3);
        }
      } else if (
        state.userData.settings.mevaho_mode === "digit" &&
        state.currentGameId === "loto-khoja"
      ) {
        if (isDoublingWon) {
          AudioUtils.playSound("receipt-breakdown-winning-khoja", 0, 3);
        }
      } else if (
        state.userData.settings.mevaho_mode === "digit" &&
        state.currentGameId === "loto-pearl"
      ) {
        if (isDoublingWon) {
          AudioUtils.playSound("receipt-breakdown-winning-pearl", 0, 3);
        }
      } else if (
        state.userData.settings.mevaho_mode === "digit" &&
        state.currentGameId === "loto-kissaho"
      ) {
        if (isDoublingWon) {
          AudioUtils.playSound("receipt-breakdown-winning-kissaho", 0, 3);
        }
      } else if (
        state.userData.settings.mevaho_mode === "digit" &&
        state.currentGameId === "loto-silk-way"
      ) {
        if (isDoublingWon) {
          AudioUtils.playSound("receipt-breakdown-winning-silk-way", 0, 3);
        }
      } else if (
        state.userData.settings.mevaho_mode === "digit" &&
        state.currentGameId === "loto-astrologer"
      ) {
        if (isDoublingWon) {
          AudioUtils.playSound("receipt-breakdown-winning-astrologer", 0, 3);
        }
      } else if (
        state.userData.settings.mevaho_mode === "digit" &&
        state.currentGameId === "loto-misr"
      ) {
        if (isDoublingWon) {
          AudioUtils.playSound("receipt-breakdown-winning-misr", 0, 3);
        }
      } else if (
        state.userData.settings.mevaho_mode === "digit" &&
        state.currentGameId === "loto-pamir-legends"
      ) {
        if (isDoublingWon) {
          AudioUtils.playSound("receipt-breakdown-winning-pamir-legends", 0, 3);
        }
      } else if (
        state.userData.settings.mevaho_mode === "digit" &&
        (state.currentGameId === "loto-gambusaki-9" ||
          state.currentGameId === "loto-gambusaki-gold-9")
      ) {
        if (isDoublingWon) {
          AudioUtils.playSound("receipt-breakdown-winning-gambusaki", 0, 3);
        }
      } else if (
        state.userData.settings.mevaho_mode === "fruit" &&
        state.currentGameId === "loto-khoja"
      ) {
        if (isDoublingWon) {
          AudioUtils.playSound("breakdown-chests-win-khoja", 0, 1);
        } else {
          AudioUtils.playSound("breakdown-chests-loss-khoja", 0, 1);
        }
      } else if (
        state.userData.settings.mevaho_mode === "fruit" &&
        state.currentGameId === "loto-pearl"
      ) {
        if (isDoublingWon) {
          AudioUtils.playSound("breakdown-chests-win-pearl", 0, 1);
        } else {
          AudioUtils.playSound("breakdown-chests-loss-pearl", 0, 1);
        }
      } else if (
        state.userData.settings.mevaho_mode === "fruit" &&
        state.currentGameId === "loto-astrologer"
      ) {
        if (isDoublingWon) {
          setTimeout(() => {
            AudioUtils.playSound("breakdown-chests-win-astrologer", 0, 1);
          }, 1000);
        } else {
          setTimeout(() => {
            AudioUtils.playSound("breakdown-chests-loss-astrologer", 0, 1);
          }, 1000);
        }
      } else if (
        state.userData.settings.mevaho_mode === "fruit" &&
        state.currentGameId === "loto-kissaho"
      ) {
        if (isDoublingWon) {
          AudioUtils.playSound("breakdown-chests-win-kissaho", 0, 1);
        } else {
          AudioUtils.playSound("breakdown-chests-loss-kissaho", 0, 1);
        }
      } else if (
        state.userData.settings.mevaho_mode === "fruit" &&
        state.currentGameId === "loto-misr"
      ) {
        if (isDoublingWon) {
          AudioUtils.playSound("breakdown-chests-win-misr", 0, 1);
        } else {
          AudioUtils.playSound("breakdown-chests-loss-misr", 0, 1);
        }
      } else if (
        state.userData.settings.mevaho_mode === "fruit" &&
        state.currentGameId === "loto-silk-way"
      ) {
        if (isDoublingWon) {
          setTimeout(() => {
            AudioUtils.playSound("breakdown-chests-win-silk-way", 0, 1);
          }, 1000);
        } else {
          setTimeout(() => {
            AudioUtils.playSound("breakdown-chests-loss-silk-way", 0, 1);
          }, 1000);
        }
      } else if (
        state.userData.settings.mevaho_mode === "fruit" &&
        state.currentGameId === "loto-pamir-legends"
      ) {
        if (isDoublingWon) {
          setTimeout(() => {
            AudioUtils.playSound("breakdown-chests-win-pamir-legends", 0, 2);
          }, 1000);
        } else {
          setTimeout(() => {
            AudioUtils.playSound("breakdown-chests-loss-pamir-legends", 0, 2);
          }, 1000);
        }
      }

      if (state.userData.settings.mevaho_mode === "fruit" && isDoublingWon) {
        commit("setLastPayout", result.payout);
        commit("updateCurrentAmount", result.payout);
        commit("updateDeferredBalance", 0);
        commit("setIsTakePayout", true);
        if (state.currentGameId === "loto-silk-way") {
          setTimeout(() => {
            commit("addStepToCount");
          }, 1000);
        } else {
          commit("addStepToCount");
        }

        window.setTimeout(
          () => {
            if (state.doubling.countGame < 5) {
              storeEvents.emit("nextPlayGame");
              // dispatch("toggleModal", "doubling");
            } else {
              storeEvents.emit("lastPlayedGame");
              window.setTimeout(() => {
                commit("setLastPayout", 0);
                dispatch("stopDoublingGame");
                dispatch("autoMakeTicket", { delay: true, force: false });
              }, CLOSE_DOUBLING_AFTER_LOST_TIMEOUT);
            }
          },
          state.currentGameId === "loto-silk-way" ||
            state.currentGameId === "loto-pamir-legends" ||
            state.currentGameId === "loto-astrologer"
            ? OPEN_BOX_TIMEOUT_SILK_WAY
            : OPEN_BOX_TIMEOUT
        );
      }

      if (state.userData.settings.mevaho_mode === "fruit" && !isDoublingWon) {
        window.setTimeout(
          () => {
            commit("setLastPayout", result.payout);
            commit("updateCurrentAmount", result.payout);
            commit("updateDeferredBalance", 0);
          },
          state.currentGameId === "loto-silk-way" ||
            state.currentGameId === "loto-pamir-legends" ||
            state.currentGameId === "loto-astrologer"
            ? OPEN_BOX_TIMEOUT_SILK_WAY
            : OPEN_BOX_TIMEOUT
        );
        window.setTimeout(() => {
          dispatch("stopDoublingGame");
          dispatch("autoMakeTicket", { delay: true, force: false });
          if (store.state.ui.lastStateIsOn === true) {
            store.dispatch("loadPromo");
            store.commit("setLastStateIsOn", false);
          }
        }, (state.currentGameId === "loto-silk-way" || state.currentGameId === "loto-pamir-legends" || state.currentGameId === "loto-astrologer" ? OPEN_BOX_TIMEOUT_SILK_WAY : OPEN_BOX_TIMEOUT) + CLOSE_DOUBLING_AFTER_LOST_TIMEOUT);
      }

      if (state.userData.settings.mevaho_mode === "digit" && isDoublingWon) {
        commit("setLastPayout", result.payout);
        commit("updateCurrentAmount", result.payout);
        commit("updateDeferredBalance", 0);
        commit("setIsTakePayout", true);
        commit("addStepToCount");
        if (state.doubling.countGame < 5) {
          // if (state.uiVersion !== "desktop") dispatch("toggleModal", "doubling");
        } else {
          commit("setMultiButtonMode", "autoGame");
          window.setTimeout(() => {
            commit("setLastPayout", 0);
            dispatch("stopDoublingGame");
            dispatch("autoMakeTicket", { delay: true, force: false });
          }, OPEN_BOX_TIMEOUT);
        }
      }

      if (state.userData.settings.mevaho_mode === "digit" && !isDoublingWon) {
        commit("addStepToCount");
        commit("setLastPayout", result.payout);
        commit("updateCurrentAmount", result.payout);
        commit("updateDeferredBalance", 0);
        commit("setMultiButtonMode", "autoGame");
        dispatch("stopDoublingGame");
        dispatch("autoMakeTicket", { delay: true, force: false });
        if (store.state.ui.lastStateIsOn === true) {
          store.dispatch("loadPromo");
          store.commit("setLastStateIsOn", false);
        }
      }
    } catch (e: any) {
      console.error("error: ", e);
      if (e.code === 13) {
        commit("setMultiButtonMode", "autoGame");
        await dispatch("stopDoublingGame");
        await dispatch("toggleModal", "expired");
      }
    } finally {
      commit("setButtonDoublingDisabled", false);
    }
  },

  async splitAmount({ state, commit, dispatch }: ACtx) {
    commit("addStepSplit");
    dispatch("recalculateExpectedWinnings");
  },

  async resetSplit({ state, commit, dispatch }: ACtx) {
    commit("clearSplit");
    commit("updateOnlyExpectedWinnings", [...state.doubling.expectedWinningsTemp]);
    commit("updateCurrentAmount", state.doubling.expectedWinnings[state.doubling.countGame]);
    commit("updateDeferredBalance", 0);
  },
  async recalculateExpectedWinnings({ state, commit, dispatch }: ACtx) {
    const countGame = store.state.doubling.countGame;
    const expected = store.state.doubling.expectedWinnings[countGame];
    const min = store.state.settings.doubling_game.split.min_amount;
    const split = store.state.doubling.split;
    const tempResult = expected / 100 / 2 ** split;
    const result = truncTwo(tempResult) * 100;
    const deferredBalance = expected - result;

    const step = state.doubling.countGame;
    const tempArray = state.doubling.expectedWinnings;
    let count = 0;
    const newWinningsArray = tempArray.map((item, idx) => {
      if (idx <= step) {
        return item;
      } else {
        count += 1;
        return result * 2 ** count;
      }
    });
    commit("updateDeferredBalance", deferredBalance);
    commit("updateCurrentAmount", result);
    commit("updateOnlyExpectedWinnings", newWinningsArray);
  },
};

export const getters = {};
