import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3af2f73c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "doubling-boxes-mevaho-gold__buttons" }
const _hoisted_2 = { class: "doubling-boxes-mevaho-gold__left" }
const _hoisted_3 = { class: "doubling-boxes-mevaho-gold__leftBox" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "doubling-boxes-mevaho-gold__right" }
const _hoisted_6 = { class: "doubling-boxes-mevaho-gold__rightBox" }
const _hoisted_7 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["doubling-boxes-mevaho-gold", { '--disabled': _ctx.disabled || _ctx.isBetstop }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goPlay('left'))),
        class: "doubling-boxes-mevaho-gold__leftButton"
      }, "LEFT"),
      _createElementVNode("div", {
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.goPlay('right'))),
        class: "doubling-boxes-mevaho-gold__rightButton"
      }, "RIGHT")
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("img", {
          class: "doubling-boxes-mevaho-gold__img",
          src: _ctx.currentImageLeft,
          alt: "box-left"
        }, null, 8, _hoisted_4)
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("img", {
          class: "doubling-boxes-mevaho-gold__img",
          src: _ctx.currentImageRight,
          alt: "box-right"
        }, null, 8, _hoisted_7)
      ])
    ])
  ], 2))
}