import store, { ACtx } from "@/store";
import State from "./state";
import { TicketResponse, UpdateTicket, UpdateTicketState } from "@/types/tickets";
import storeEvents from "@/store-events";
import { DrawItem } from "@/types/draws";
import {
  DELAY_STATUSES,
  FRUIT_AUTOPLAY_LOST_DELAY,
  MAKE_TICKET_DELAY,
  MAKE_TICKET_DELAY_DIGIT,
  MAKE_TICKET_DELAY_MEVAHO_GOLD,
  TYPICAL_ANIMATION_TIME,
  TYPICAL_ANIMATION_TIME_DIGIT,
  TYPICAL_ANIMATION_TIME_MEVAHO_GOLD,
} from "@/constants";
import { AudioUtils } from "@/utils";
import { api } from "@/api";
import { calculateGambusakiTicketGlow, calculateKhojaTicketGlow } from "@/utils/ticket-calculator";
import router from "@/router";

export const state: UpdateTicketState = undefined;
let timerMakeBonusTicket: number | undefined = undefined;

export const mutations = {
  setCurrentTicket(state: State, ticket: UpdateTicket) {
    state.currentTicket = ticket;
  },
  updateTicketId(state: State, data: TicketResponse) {
    if (state.currentTicket) {
      state.currentTicket = {
        ...state.currentTicket,
        ticket_id: data.ticket_id,
        received: data.received,
      };
    } else {
      state.currentTicket = {
        ticket_id: data.ticket_id,
        received: data.received,
        draw_id: 0,
        calculated: 0,
        draw: {
          id: 0,
          numbers: [],
        },
        payout_by_line: {
          lines_payout: [],
          bonus_payout: 0,
        },
        status: "",
        has_free_tickets: false,
        is_free_ticket: false,
        has_doubling: false,
      };
    }
  },
  clearCurrentTicket(state: State) {
    state.currentTicket = undefined;
  },
  updateCurrentTicketHistory(state: State, ticket: DrawItem) {
    state.currentTicket = {
      calculated: ticket.calculated,
      draw_id: ticket.id,
      status: ticket.status,
      draw: {
        id: ticket.id,
        numbers: ticket.numbers,
      },
      payout_by_line: {
        lines_payout: [],
        bonus_payout: 0,
      },
      has_doubling: false,
      has_free_tickets: false,
      is_free_ticket: false,
    };
  },
};

export const actions = {
  setCurrentTicket({ state, commit, dispatch }: ACtx, ticket: UpdateTicket) {
    const currentDelay =
      state.userData.settings.mevaho_mode === "digit"
        ? MAKE_TICKET_DELAY_DIGIT
        : state.currentGameId === "loto-funny-fruits"
        ? MAKE_TICKET_DELAY_MEVAHO_GOLD
        : MAKE_TICKET_DELAY;
    const createdTimeTicket = state.createTicket.created;
    const tempDate = new Date().getTime();
    const diffTime = currentDelay - (tempDate - createdTimeTicket);
    const resultDelay = DELAY_STATUSES.includes(ticket.status) && diffTime > 0 ? diffTime : 0;
    const animDelay =
      state.userData.settings.mevaho_mode === "digit"
        ? TYPICAL_ANIMATION_TIME_DIGIT
        : TYPICAL_ANIMATION_TIME;
    const isGambusaki =
      state.currentGameId === "loto-gambusaki-9" ||
      state.currentGameId === "loto-gambusaki-gold-9" ||
      state.currentGameId === "loto-khoja" ||
      state.currentGameId === "loto-kissaho" ||
      state.currentGameId === "loto-pearl" ||
      state.currentGameId === "loto-silk-way" ||
      state.currentGameId === "loto-pamir-legends" ||
      state.currentGameId === "loto-astrologer" ||
      state.currentGameId === "loto-misr";
    const isTurnOnBonusGames = state.settings.bonus_games.enabled;
    const hasBonus = ticket.has_free_tickets && isGambusaki && isTurnOnBonusGames;

    setTimeout(async () => {
      if (
        ticket.status === "lost" &&
        store.state.ui.promoTicketsLeft === store.state.promo.promo?.quantity &&
        store.state.ui.promoIsOn === true
      ) {
        store.commit("setPromoIsOn", false);
      }
      if (ticket.status === "lost" || ticket.status === "won") {
        commit("setCurrentTicket", ticket);
        commit("setLastTicket", ticket);
        storeEvents.emit("afterTicketUpdate", ticket);

        commit("setShowAnimation", "open");
        if (state.userData.settings.mevaho_mode === "digit") {
          AudioUtils.stopSound("receipt-erase");
          AudioUtils.playSound("receipt-erase", 0, TYPICAL_ANIMATION_TIME_DIGIT);
        }
        setTimeout(() => {
          commit("setShowAnimation", undefined);
          commit("setControlDisabled", false);
          commit("setMakeTicketDisabled", state.ui.autoGame);
        }, animDelay);
      }
      if (ticket.status === "won" && !hasBonus) {
        dispatch("startDoublingGame", { ticket, animDelay });
        if (!state.ui.autoGame && state.currentGameId === "loto-funny-fruits") {
          dispatch("mevahoGoldAutoDoublingTimer");
        }
      }
      if (ticket.status === "won" && hasBonus) {
        dispatch("startBonusGame", { ticket, animDelay });
      }
      if (ticket.status === "lost") {
        if (state.ui.autoGame && state.userData.settings.mevaho_mode === "digit") {
          setTimeout(() => {
            dispatch("makeTicket");
          }, animDelay);
        } else if (state.ui.autoGame && state.userData.settings.mevaho_mode === "fruit") {
          setTimeout(() => {
            dispatch("makeTicket");
          }, FRUIT_AUTOPLAY_LOST_DELAY + animDelay);
        }
      }
      if (ticket.status === "won") {
        setTimeout(() => {
          if (
            !hasBonus &&
            state.settings.doubling_game.enabled &&
            state.userData.settings.mevaho_mode === "fruit"
          ) {
            /// AudioUtils.playSound("win-drop-sound", 0);
            /// AudioUtils.playSound("win-drop-sound", 0);
          } else if (hasBonus && state.currentGameId === "loto-khoja") {
            AudioUtils.stopSound("getting-free-tickets-short-khoja");
            AudioUtils.stopBackgroundMusic("loto-khoja", "main");
            AudioUtils.playSound("getting-free-tickets-short-khoja", 0);
            if (state.userData.settings.mevaho_mode === "fruit") {
              if (state.userData.settings.sound === "off") {
                AudioUtils.playBackgroundMusic("loto-khoja", "bonus");
              } else {
                AudioUtils.playSound("getting-free-tickets-short-khoja", 0, 0, false, () =>
                  AudioUtils.playBackgroundMusic("loto-khoja", "bonus")
                );
              }
            }
          } else if (hasBonus && state.currentGameId === "loto-kissaho") {
            AudioUtils.stopSound("getting-free-tickets-short-kissaho");
            AudioUtils.stopBackgroundMusic("loto-kissaho", "main");
            AudioUtils.playSound("getting-free-tickets-short-kissaho", 0);
            if (state.userData.settings.mevaho_mode === "fruit") {
              if (state.userData.settings.sound === "off") {
                AudioUtils.playBackgroundMusic("loto-kissaho", "bonus");
              } else {
                AudioUtils.playSound("getting-free-tickets-short-kissaho", 0, 0, false, () =>
                  AudioUtils.playBackgroundMusic("loto-kissaho", "bonus")
                );
              }
            }
          } else if (hasBonus && state.currentGameId === "loto-astrologer") {
            AudioUtils.stopSound("getting-free-tickets-short-astrologer");
            AudioUtils.stopBackgroundMusic("loto-astrologer", "main");
            AudioUtils.playSound("getting-free-tickets-short-astrologer", 0);
            if (state.userData.settings.mevaho_mode === "fruit") {
              if (state.userData.settings.sound === "off") {
                AudioUtils.playBackgroundMusic("loto-astrologer", "bonus");
              } else {
                AudioUtils.playSound("getting-free-tickets-short-astrologer", 0, 0, false, () =>
                  AudioUtils.playBackgroundMusic("loto-astrologer", "bonus")
                );
              }
            }
          } else if (hasBonus && state.currentGameId === "loto-pamir-legends") {
            AudioUtils.stopSound("getting-free-tickets-short-pamir-legends");
            AudioUtils.stopBackgroundMusic("loto-pamir-legends", "main");
            AudioUtils.playSound("getting-free-tickets-short-pamir-legends", 0);
            if (state.userData.settings.mevaho_mode === "fruit") {
              if (state.userData.settings.sound === "off") {
                AudioUtils.playBackgroundMusic("loto-pamir-legends", "bonus");
              } else {
                AudioUtils.playSound("getting-free-tickets-short-pamir-legends", 0, 0, false, () =>
                  AudioUtils.playBackgroundMusic("loto-pamir-legends", "bonus")
                );
              }
            }
          } else if (hasBonus && state.currentGameId === "loto-pearl") {
            AudioUtils.stopSound("getting-free-tickets-short-pearl");
            AudioUtils.stopBackgroundMusic("loto-pearl", "main");
            AudioUtils.playSound("getting-free-tickets-short-pearl", 0);
            if (state.userData.settings.mevaho_mode === "fruit") {
              if (state.userData.settings.sound === "off") {
                AudioUtils.playBackgroundMusic("loto-pearl", "bonus");
              } else {
                AudioUtils.playSound("getting-free-tickets-short-pearl", 0, 0, false, () =>
                  AudioUtils.playBackgroundMusic("loto-pearl", "bonus")
                );
              }
            }
          } else if (hasBonus && state.currentGameId === "loto-misr") {
            AudioUtils.stopSound("getting-free-tickets-short-misr");
            AudioUtils.stopBackgroundMusic("loto-misr", "main");
            AudioUtils.playSound("getting-free-tickets-short-misr", 0);
            if (state.userData.settings.mevaho_mode === "fruit") {
              if (state.userData.settings.sound === "off") {
                AudioUtils.playBackgroundMusic("loto-misr", "bonus");
              } else {
                AudioUtils.playSound("getting-free-tickets-short-misr", 0, 0, false, () =>
                  AudioUtils.playBackgroundMusic("loto-misr", "bonus")
                );
              }
            }
          } else if (hasBonus && state.currentGameId === "loto-gambusaki-gold-9") {
            AudioUtils.stopSound("getting-free-tickets");
            AudioUtils.stopBackgroundMusic("loto-gambusaki-gold-9", "main");
            AudioUtils.playSound("getting-free-tickets", 0);
            if (state.userData.settings.mevaho_mode === "fruit") {
              if (state.userData.settings.sound === "off") {
                AudioUtils.playBackgroundMusic("loto-gambusaki-gold-9", "bonus");
              } else {
                AudioUtils.playSound("getting-free-tickets", 0, 0, false, () =>
                  AudioUtils.playBackgroundMusic("loto-gambusaki-gold-9", "bonus")
                );
              }
            }
          } else if (hasBonus && state.currentGameId === "loto-silk-way") {
            AudioUtils.stopSound("getting-free-tickets-silk-way");
            AudioUtils.stopBackgroundMusic("loto-silk-way", "main");
            AudioUtils.playSound("getting-free-tickets-silk-way", 0);
            if (state.userData.settings.mevaho_mode === "fruit") {
              if (state.userData.settings.sound === "off") {
                AudioUtils.playBackgroundMusic("loto-silk-way", "bonus");
              } else {
                AudioUtils.playSound("getting-free-tickets-silk-way", 0, 0, false, () =>
                  AudioUtils.playBackgroundMusic("loto-silk-way", "bonus")
                );
              }
            }
          } else if (hasBonus && state.currentGameId === "loto-gambusaki-9") {
            AudioUtils.stopSound("getting-free-tickets");
            AudioUtils.playSound("getting-free-tickets", 0);
          } else {
            // AudioUtils.stopSound("receipt-win-drop-sound");
            // AudioUtils.playSound("receipt-win-drop-sound", 0);
          }
        }, animDelay);
      }
      if (
        ticket.status === "gamestop" ||
        ticket.status === "internal-error" ||
        ticket.status === "out-of-limits"
      ) {
        await store.dispatch("setErrorStatus", ticket.status);
        dispatch("loadLastTicket");
      }
      if (ticket.status === "promo-failed") {
        store.commit("setPromoIsOn", false);
        await store.dispatch("setErrorStatus", ticket.status);
      }
      commit("clearCount");
    }, resultDelay);
  },

  startBonusGame(
    { state, commit, dispatch }: ACtx,
    { ticket, animDelay }: { ticket: UpdateTicket; animDelay: number }
  ) {
    setTimeout(() => {
      commit("setLastPayout", ticket.payout);
      commit("clearBonusCount");
      commit("setNumGames", state.settings.bonus_games.num_games);
      commit("setControlDisabled", true);
      commit("setMultiButtonMode", "bonus");
      commit("setShowBonusModal", { show: true, start: true });
    }, animDelay);
  },

  startDoublingGame(
    { state, commit, dispatch }: ACtx,
    { ticket, animDelay }: { ticket: UpdateTicket; animDelay: number }
  ) {
    commit("resetGameInfo");
    dispatch("startDoublingTimer");
    setTimeout(() => {
      // commit("setLastPayout", ticket.payout);
      if (state.settings.doubling_game.enabled && !state.ui.autoGame) {
        commit("updateExpectedWinnings", ticket.payout);
        commit("setMultiButtonMode", "doubling");
      } else if (state.settings.doubling_game.enabled && state.ui.autoGame) {
        commit("updateExpectedWinnings", ticket.payout);
        commit("setMultiButtonMode", "autoDoubling");
        dispatch("startAutoDoublingTimer");
      }
    }, animDelay);
  },

  setBonusTicket({ state, commit, dispatch }: ACtx, ticket: UpdateTicket) {
    const currentDelay =
      state.userData.settings.mevaho_mode === "digit" ? MAKE_TICKET_DELAY_DIGIT : MAKE_TICKET_DELAY;
    const createdTimeTicket = state.createTicket.created;
    const tempDate = new Date().getTime();
    const diffTime = currentDelay - (tempDate - createdTimeTicket);
    const resultDelay = DELAY_STATUSES.includes(ticket.status) && diffTime > 0 ? diffTime : 0;
    const animDelay =
      state.userData.settings.mevaho_mode === "digit"
        ? TYPICAL_ANIMATION_TIME_DIGIT
        : TYPICAL_ANIMATION_TIME;

    const numGames = state.bonus.numGames - 1;
    const currentPayout = ticket.payout ?? 0;
    const gameId = state.currentGameId;
    const numbers = ticket.draw.numbers;
    const hasDoubling = ticket.has_doubling;
    const hasBonus = ticket.has_free_tickets;
    // const bonusWon =
    //   gameId === "loto-gambusaki-gold-9"
    //     ? calcBonusNumbers(numbers)
    //     : calcBonusNumbersKhoja(numbers);
    const hasBonusInBonus =
      (gameId === "loto-gambusaki-gold-9" ||
        gameId === "loto-khoja" ||
        gameId === "loto-kissaho" ||
        gameId === "loto-pearl" ||
        gameId === "loto-silk-way" ||
        gameId === "loto-astrologer" ||
        gameId === "loto-misr" ||
        gameId === "loto-pamir-legends") &&
      ticket.is_free_ticket &&
      hasBonus;
    const bonusDoubling = hasDoubling ? 2 : 1;
    const sumPayout = (state.ui.lastPayout + currentPayout) * bonusDoubling;
    const currentCombination =
      state.createTicket && state.createTicket.combination
        ? state.createTicket.combination
        : "combi-5";
    const lengthLinesWon =
      ticket.draw && ticket.draw.numbers && ticket.draw.numbers.length > 0
        ? gameId === "loto-khoja" ||
          gameId === "loto-kissaho" ||
          gameId === "loto-pearl" ||
          gameId === "loto-silk-way" ||
          gameId === "loto-pamir-legends" ||
          gameId === "loto-astrologer" ||
          gameId === "loto-misr"
          ? calculateKhojaTicketGlow(ticket.draw.numbers, currentCombination, gameId).length
          : calculateGambusakiTicketGlow(ticket.draw.numbers, currentCombination, gameId).length
        : 1;
    let bonusAnimDelayWon = 0;
    let bonusAnimDelayLost = 0;
    if (hasDoubling) commit("setRandomBonusAnim", resultDelay);
    if (hasDoubling && state.userData.settings.mevaho_mode === "fruit") {
      bonusAnimDelayLost = bonusAnimDelayWon = state.bonus.animDoublingBonusTime;
    } else if (hasDoubling && state.userData.settings.mevaho_mode === "digit") {
      bonusAnimDelayLost = bonusAnimDelayWon = 2000;
    } else if (state.userData.settings.mevaho_mode === "digit") {
      bonusAnimDelayLost = animDelay;
      bonusAnimDelayWon = 2000 * lengthLinesWon;
    } else {
      bonusAnimDelayLost = FRUIT_AUTOPLAY_LOST_DELAY + animDelay;
      bonusAnimDelayWon = lengthLinesWon === 1 ? 3000 : 2000 * lengthLinesWon + 1500;
    }
    // store.commit("setIsSkipDisabled", false);
    setTimeout(async () => {
      if (ticket.status === "lost" || ticket.status === "won") {
        commit("setTempPayout", state.ui.lastPayout);
        commit("setCurrentTicket", ticket);

        storeEvents.emit("afterTicketUpdate", ticket);
        commit("setShowAnimation", "open");
        if (state.userData.settings.mevaho_mode === "digit") {
          AudioUtils.stopSound("receipt-erase");
          AudioUtils.playSound("receipt-erase", 0, TYPICAL_ANIMATION_TIME_DIGIT);
        }
        setTimeout(() => {
          if (hasDoubling) commit("setLastPayout", sumPayout);
          if (hasBonusInBonus) {
            store.commit("setLastPayout", state.ui.tempPayout + currentPayout);
            store.commit("setIsBonusInBonusProcessing", true);
          }
          commit("setShowAnimation", undefined);
          commit("setIsWon", ticket.status === "won");
          commit("addStepToBonusCount");
        }, animDelay);
      }

      if (hasBonus && (ticket.status === "lost" || ticket.status === "won")) {
        setTimeout(() => {
          dispatch("makeBonusInBonusTicket");
          commit("setNumGames", ticket.free_tickets_total);
          store.commit("setIsSkipDisabled", false);
          if (state.currentGameId === "loto-khoja") {
            AudioUtils.stopSound("getting-free-tickets-short-khoja");
            AudioUtils.playSound("getting-free-tickets-short-khoja", 0);
          } else if (state.currentGameId === "loto-kissaho") {
            AudioUtils.stopSound("getting-free-tickets-short-kissaho");
            AudioUtils.playSound("getting-free-tickets-short-kissaho", 0);
          } else if (state.currentGameId === "loto-silk-way") {
            AudioUtils.stopSound("getting-free-tickets-short-silk-way");
            AudioUtils.playSound("getting-free-tickets-short-silk-way", 0);
          } else if (state.currentGameId === "loto-astrologer") {
            AudioUtils.stopSound("getting-free-tickets-short-astrologer");
            AudioUtils.playSound("getting-free-tickets-short-astrologer", 0);
          } else if (state.currentGameId === "loto-pamir-legends") {
            AudioUtils.stopSound("getting-free-tickets-short-pamir-legends");
            AudioUtils.playSound("getting-free-tickets-short-pamir-legends", 0);
          } else if (state.currentGameId === "loto-pearl") {
            AudioUtils.stopSound("getting-free-tickets-short-pearl");
            AudioUtils.playSound("getting-free-tickets-short-pearl", 0);
          } else if (state.currentGameId === "loto-misr") {
            AudioUtils.stopSound("getting-free-tickets-short-misr");
            AudioUtils.playSound("getting-free-tickets-short-misr", 0);
          } else if (state.currentGameId === "loto-gambusaki-gold-9") {
            AudioUtils.stopSound("getting-free-tickets");
            AudioUtils.playSound("getting-free-tickets", 0);
          } else {
            AudioUtils.stopSound("getting-free-tickets-short");
            AudioUtils.playSound("getting-free-tickets-short", 0);
          }
        }, animDelay);
      } else if (
        state.bonus.countGame < numGames &&
        (ticket.status === "lost" || ticket.status === "won")
      ) {
        if (ticket.status === "lost") {
          if (hasDoubling && state.userData.settings.mevaho_mode === "digit") {
            setTimeout(() => {
              AudioUtils.stopSound("receipt-win-drop-sound");
              AudioUtils.playSound("receipt-win-drop-sound", 0);
            }, animDelay);
          }
          timerMakeBonusTicket = window.setTimeout(() => {
            dispatch("makeBonusTicket");
          }, bonusAnimDelayLost);
        }

        if (ticket.status === "won") {
          setTimeout(() => {
            if (!hasDoubling && state.userData.settings.mevaho_mode === "fruit") {
              // AudioUtils.playSound("win-drop-sound", 0, 3);
            } else if (state.userData.settings.mevaho_mode === "digit") {
              // AudioUtils.stopSound("receipt-win-drop-sound");
              // AudioUtils.playSound("receipt-win-drop-sound", 0);
            }
            // AudioUtils.stopSound("zero-drop-highlight");
            // AudioUtils.playSound("zero-drop-highlight", 0, 1);
            store.commit("setIsSkipDisabled", false);
          }, animDelay);

          timerMakeBonusTicket = window.setTimeout(() => {
            dispatch("makeBonusTicket");
          }, animDelay + bonusAnimDelayWon);
        }
      } else if (
        state.bonus.countGame >= numGames &&
        (ticket.status === "lost" || ticket.status === "won")
      ) {
        store.commit("setIsSkipDisabled", true);
        setTimeout(() => {
          if (!hasDoubling && state.userData.settings.mevaho_mode === "fruit") {
            /// AudioUtils.playSound("win-drop-sound", 0, 3);
          } else if (state.userData.settings.mevaho_mode === "digit") {
            // AudioUtils.stopSound("receipt-win-drop-sound");
            // AudioUtils.playSound("receipt-win-drop-sound", 0);
          }
        }, animDelay);
        window.setTimeout(() => {
          commit("setIsBonusOn", false);
          commit("setShowBonusPersons", false);
          if (state.userData.settings.mevaho_mode === "fruit") {
            if (state.currentGameId === "loto-pamir-legends") {
              AudioUtils.stopBackgroundMusic("loto-pamir-legends", "bonus");
              AudioUtils.playBackgroundMusic("loto-pamir-legends", "main");
            } else if (state.currentGameId === "loto-pearl") {
              AudioUtils.stopBackgroundMusic("loto-pearl", "bonus");
              AudioUtils.playBackgroundMusic("loto-pearl", "main");
            } else if (state.currentGameId === "loto-misr") {
              AudioUtils.stopBackgroundMusic("loto-misr", "bonus");
              AudioUtils.playBackgroundMusic("loto-misr", "main");
            } else if (state.currentGameId === "loto-khoja") {
              AudioUtils.stopBackgroundMusic("loto-khoja", "bonus");
              AudioUtils.playBackgroundMusic("loto-khoja", "main");
            } else if (state.currentGameId === "loto-astrologer") {
              AudioUtils.stopBackgroundMusic("loto-astrologer", "bonus");
              AudioUtils.playBackgroundMusic("loto-astrologer", "main");
            } else if (state.currentGameId === "loto-kissaho") {
              AudioUtils.stopBackgroundMusic("loto-kissaho", "bonus");
              AudioUtils.playBackgroundMusic("loto-kissaho", "main");
            } else if (state.currentGameId === "loto-gambusaki-gold-9") {
              AudioUtils.stopBackgroundMusic("loto-gambusaki-gold-9", "bonus");
              AudioUtils.playBackgroundMusic("loto-gambusaki-gold-9", "main");
            } else if (state.currentGameId === "loto-silk-way") {
              AudioUtils.stopBackgroundMusic("loto-silk-way", "bonus");
              AudioUtils.playBackgroundMusic("loto-silk-way", "main");
            }
          }

          commit("setCurrentTicket", state.lastTicket);
          if (state.lastTicket) storeEvents.emit("afterTicketUpdate", state.lastTicket, true);
          commit("setControlDisabled", false);
          commit("setMakeTicketDisabled", state.ui.autoGame);
          commit("resetRandomBonusAnim");
          commit("resetGameInfo");
          // dispatch("startDoublingTimer");
          if (state.settings.doubling_game.enabled && !state.ui.autoGame) {
            commit("updateExpectedWinnings", sumPayout);
            commit("setMultiButtonMode", "doubling");
          } else if (state.settings.doubling_game.enabled && state.ui.autoGame) {
            commit("updateExpectedWinnings", sumPayout);
            commit("setMultiButtonMode", "autoDoubling");
            if (
              state.userData.settings.autogame_continuation === "on" &&
              (state.currentGameId === "loto-gambusaki-9" ||
                state.currentGameId === "loto-gambusaki-gold-9" ||
                state.currentGameId === "loto-khoja" ||
                state.currentGameId === "loto-kissaho" ||
                state.currentGameId === "loto-silk-way" ||
                state.currentGameId === "loto-pearl" ||
                state.currentGameId === "loto-astrologer" ||
                state.currentGameId === "loto-misr" ||
                state.currentGameId === "loto-pamir-legends")
            ) {
              dispatch("startAutoDoublingTimer", true);
            }
          }

          commit("setShowBonusModal", { show: true, start: false });
        }, animDelay + bonusAnimDelayWon);
      }

      if (
        ticket.status === "gamestop" ||
        ticket.status === "internal-error" ||
        ticket.status === "out-of-limits"
      ) {
        await store.dispatch("setErrorStatus", ticket.status);
        dispatch("loadLastTicket");
      }
    }, resultDelay);
  },

  async loadLastTicket({ state, commit, dispatch }: ACtx) {
    const lastDraw = await api.getDrawHistory({ limit: 1, offset: 0 });
    commit("setAutoGame", false);
    storeEvents.emit("afterErrorTicketUpdate", lastDraw.items[0]);
    commit("setShowAnimation", undefined);
    commit("setControlDisabled", false);
    commit("setMakeTicketDisabled", state.ui.autoGame);
  },
  cancelTimerMakeBonusTicket() {
    window.clearTimeout(timerMakeBonusTicket);
  },
};

export const getters = {};
